import React, { useEffect, useState } from "react";

import { useAuth } from "../../context/AuthContext";
import { ExportToExcel, excelMapping, now } from "../utils/utilFuctions";
import ComingSoonImage from "../../assets/animation/Coming Soon (1).json";
import Lottie from "lottie-react";

import { Button, Form } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { SearchOutlined } from "@ant-design/icons";
import { useLocation } from "react-router-dom";
import Select from "react-select";

import { Pagination } from "../utils/Pagination";
import "../UpiIntentReport.css";
import "react-datepicker/dist/react-datepicker.css";

const arr = [{ label: "Name", value: "Name" }];

const dateBefore1Week = () => {
  const today = new Date();
  const lastWeek = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate() - 7
  );
  return lastWeek;
};

const options = [
  { label: "Success", value: "success" },
  { label: "Failed", value: "n" },
];

export const columns = [
  {
    dataField: "ref_id",
    text: "Ref Id",
    color: "#586275",
  },
  {
    dataField: "rrn",
    text: "RRN",
    color: "#586275",
  },
  {
    dataField: "opening_bal",
    text: "Opening Bal",
    color: "#586275",
  },
  {
    dataField: "amount",
    text: "Transaction amount",
    color: "#586275",
  },
  {
    dataField: "charge",
    text: "Charges",
    color: "#586275",
  },
  {
    dataField: "GST",
    text: "GST",
    color: "#586275",
  },
  {
    dataField: "closing_bal",
    text: "Closing Bal",
    color: "#586275",
  },

  {
    dataField: "date",
    text: "Date",
    color: "#586275",
  },
  {
    dataField: "status",
    text: "Status",
    color: "#586275",
  },
];

const SuspectedReport = ({ status }) => {
  const { user, loaderState, setTransactionLoaderState } = useAuth();
  const [reportData, setReportData] = useState([]);
  const [startDate, setStartDate] = useState(dateBefore1Week());
  const [endDate, setEndDate] = useState(new Date());
  const [type, setType] = useState("1");
  const [data, setData] = useState([]);
  const [dataToDisplay, setDataToDisplay] = useState([]);
  const [loading, setLoading] = useState(true);

  const [searchText, setSearchText] = useState("");
  const [selectedStatus, setSelectedStatus] = useState(null);

  const [currentPage, setCurrentPage] = useState(1);
  const [offset, setOffset] = useState(50);
  const [total, setTotal] = useState(0);
  const [pages, setPages] = useState(0);

  const [ledger, setLedger] = useState([]);

  const dateFormatter = (d) => {
    const date = new Date(d);
    return `${date.getFullYear()}-${
      date.getMonth() > 9 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1)
    }-${date.getDate() > 9 ? date.getDate() : "0" + date.getDate()}`;
  };

  useEffect(() => {
    setPages(Math.floor((ledger.length + (offset - 1)) / offset));
    const startIdx = 0;
    const endIdx = offset - 1;
    setTotal(ledger.length);
    const filteredData = ledger.filter((bank, idx) => {
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
  }, [ledger]);

  useEffect(() => {
    let filteredData = data.filter((bank) => {
      let b = false;
      if (data.length > 0) {
        for (let key in data[0]) {
          if (
            bank[key] &&
            bank[key]
              .toString()
              .toLowerCase()
              .includes(searchText.toLowerCase())
          ) {
            b = true;
          }
        }
      }
      return b;
    });
    setPages(Math.floor((filteredData.length + 999) / 1000));
    setTotal(filteredData.length);
    const startIdx = 0;
    const endIdx = 999;
    filteredData = filteredData.filter((bank, idx) => {
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
    setCurrentPage(1);
  }, [searchText]);

  useEffect(() => {
    let filteredData = data.filter((bank) => {
      let b = false;
      if (data.length > 0) {
        for (let key in data[0]) {
          if (
            bank[key] &&
            bank[key]
              .toString()
              .toLowerCase()
              .includes(searchText.toString().toLowerCase())
          ) {
            b = true;
          }
        }
      }
      return b;
    });
    console.log(filteredData);
    const startIdx = (currentPage - 1) * offset;
    const endIdx = currentPage * offset - 1;
    console.log({ startIdx, endIdx });
    filteredData = filteredData.filter((bank, idx) => {
      console.log(idx, idx <= endIdx && idx >= startIdx);
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
    console.log({ startIdx, endIdx });
  }, [currentPage, offset]);

  // did mount
  useEffect(() => {
    setPages(Math.floor((data.length + (offset - 1)) / offset));
    const startIdx = 0;
    const endIdx = offset - 1;
    setTotal(data.length);
    console.log(startIdx);
    console.log(data);
    const filteredData = data.filter((bank, idx) => {
      return idx <= endIdx && idx >= startIdx;
    });
    console.log(filteredData);
    setDataToDisplay(filteredData);
  }, [data, offset]);

  useEffect(() => {
    setPages(Math.floor((ledger.length + (offset - 1)) / offset));
    const startIdx = 0;
    const endIdx = offset - 1;
    setTotal(ledger.length);
    const filteredData = ledger.filter((bank, idx) => {
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
  }, [ledger]);

  const ViewUPIIntentReport = async () => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        merchantId: user.merchantId == "2" ? "0" : user.merchantId,
        fromDate: dateFormatter(startDate),
        toDate: dateFormatter(endDate),
        status: status,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };
      setTransactionLoaderState(true);
      const res = await fetch(
        `https://reports.remittpe.com/api/Intent/GetAllUpiIntentReportV2`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        setData(data.data);
        setDataToDisplay(data.data);
      }
      setLoading(false);
      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
      setLoading(false);
      setTransactionLoaderState(false);
    }
  };


  const handleSubmit = (event) => {
    event.preventDefault();
    // Call the API with the selected date range
    ViewUPIIntentReport();
  };

  function getStatusBadgeClass(status) {
    switch (status) {
      case "SUCCESS":
        return "bg-success text-white";
      case "N":
        return "bg-danger text-white";
      default:
        return "bg-light text-dark";
    }
  }

  useEffect(() => {
    ViewUPIIntentReport();
  }, []);

  useEffect(() => {
    if (selectedStatus) {
      const filteredData = data.filter((e) => {
        return e.status.toString().toLowerCase() == selectedStatus.value;
      });
      setDataToDisplay(filteredData);
    }
  }, [selectedStatus]);

  return (
    <>
      <div className="row m-2 p-0 pt-4 align-items-center" id="parentFilters">
        <div
          className="m-0 p-0 pl-2 flex-grow-1 d-flex "
          style={{
            fontFamily: "Poppins",
            fontSize: "32px",
            fontWeight: "600",
            lineHeight: "48px",
            letterSpacing: "0em",
            textAlign: "left",
            padding: "0px",
            background: "#FFFFFF",
            color: "#4267B2",
            flex: 1,
            fontWeight: "bolder",
            fontfamily: "Poppins !impotent",
            fontSize: "28px",
            minWidth: "150px",
          }}
        >
          UPI Intent Report
        </div>
        <div id="filters" style={{ gap: 3, display: "flex" }}>
          <div id="searchBarStyle" className=" bg-light m-1 p-0 ">
            <SearchOutlined
              className=" m-0 p-2 "
              style={{ background: "white", color: "grey" }}
            />
            <input
              type="text"
              className="form-control"
              placeholder="Search text..."
              style={{ border: "none" }}
              onChange={(e) => {
                setSearchText(e.target.value);
              }}
              value={searchText}
            />
          </div>
          <div className="col p-0 m-0" style={{ minWidth: "120px" }}>
            <Select
              options={options}
              value={selectedStatus}
              onChange={(e) => {
                setSelectedStatus(e);
              }}
              style={{
                display: "flex",
                width: "100%",
                maxWidth: "50%",
                backgroundColor: "red",
                border: "none",
              }}
            />
          </div>
          <Form.Group
            className="p-0 m-0 "
            id="parentDatePicker"
            style={{ minWidth: "250px", display: "flex", gap: 3 }}
          >
            <DatePicker
              className="form-control rounded-1 d-flex flex-grow-1"
              style={{
                borderColor: "#ccc",
              }}
              selected={startDate}
              onChange={(date) => setStartDate(date)}
            />
            <DatePicker
              className="form-control rounded-1 d-flex flex-grow-1"
              style={{
                borderColor: "#ccc",
              }}
              selected={endDate}
              onChange={(date) => setEndDate(date)}
            />
          </Form.Group>
          <div className="d-flex align-items-center" id="searchButton">
            <Button
              className="rounded-50 btn btn-primary btn-lg d-flex flex-grow-1 "
              style={{ fontSize: "0.7rem" }}
              onClick={ViewUPIIntentReport}
            >
              {"Search"}
            </Button>
          </div>
          <div
            className=" mb-3 d-flex align-items-center justify-content-center "
            style={{
              cursor: "pointer",
              border: "1px solid",
              marginTop: "15px",
              width: "11rem",
              alignItems: "center",
              backgroundColor: "rgba(43, 193, 85, 0.1)",
              color: "rgba(43, 193, 85, 1)",
              bordercolor: "rgba(43, 193, 85, 1)",
              borderRadius: ".4rem",
              minWidth: "170px",
            }}
            onClick={() => {
              ExportToExcel(
                `Payin_Report_${now()}`,
                excelMapping(data, columns)
              );
            }}
          >
            <img
              alt=""
              src={require("../../assets/images/excel.png")}
              style={{ width: "1.6rem", marginLeft: "15px" }}
            />
            <strong
              className="d-flex align-items-center m-2 "
              style={{ minWidth: "120px", fontSize: "0.8rem" }}
            >
              Export to Excel
            </strong>
          </div>
        </div>
      </div>
      <div className=" bg-white p-2">
        <table className="table" style={{ fontFamily: "poppins" }}>
          <thead>
            <tr>
              <th className=" text-white text-center py-3 bg-primary">
                MerchantId Id
              </th>
              <th className=" text-white text-center py-3 bg-primary">
                Ref Id
              </th>
              <th className="bg-primary text-white text-center py-3 ">RRN</th>
              <th className="bg-primary text-white text-center py-3 ">
                Amount
              </th>
              <th className="bg-primary text-white text-center py-3 ">
                Charges
              </th>
              <th className="bg-primary text-white text-center py-3 ">GST</th>
              <th className="bg-primary text-white text-center py-3 ">Date</th>
              <th className="bg-primary text-center py-3 text-white text-center py-3">
                Status
              </th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td colSpan="9" className="text-center">
                  Loading...
                </td>
              </tr>
            ) : (
              dataToDisplay.map((item) => (
                <tr key={item.UserName}>
                  <td className="text-center align-middle">
                    {item.fk_user_id || "NA"}
                  </td>
                  <td className="text-center align-middle">
                    {item.pk_txnid || "NA"}
                  </td>
                  <td className="text-center align-middle">
                    {item.rrn || "NA"}
                  </td>
                  <td className="text-center align-middle">
                    {item.amount !== undefined
                      ? parseFloat(item.amount).toFixed(2)
                      : "NA"}
                  </td>
                  <td className="text-center align-middle">
                    {item.charge !== undefined && item.status === "SUCCESS"
                      ? parseFloat(item.charge).toFixed(2)
                      : "NA"}
                  </td>
                  <td className="text-center align-middle">
                    {item.gst !== undefined && item.status === "SUCCESS"
                      ? parseFloat(item.gst).toFixed(2)
                      : "NA"}
                  </td>
                  <td className="text-center align-middle">
                    {item.date || "NA"}
                  </td>
                  <td className="text-center align-middle">
                    {item.status !== undefined &&
                    item.status.toString().toLowerCase() === "success" ? (
                      <label
                        style={{
                          padding: "8px",
                          border: "1px solid",
                          height: "2rem",
                          width: "100px",
                          backgroundColor: "rgba(43, 193, 85, 0.1)",
                          color: "rgba(43, 193, 85, 1)",
                          borderColor: "rgba(43, 193, 85, 1)",
                          borderRadius: ".4rem",
                          fontSize: ".8rem",
                          display: "inline-block",
                          textAlign: "center",
                        }}
                        className="badge badge-success"
                      >
                        {item.status}
                      </label>
                    ) : item.status !== undefined &&
                      item.status
                        .toString()
                        .toLowerCase()
                        .includes("expire") ? (
                      <label
                        style={{
                          padding: "8px",
                          borderRadius: ".4rem",
                          width: "100px",
                          backgroundColor: "rgba(255, 0, 0, 0.1)",
                          color: "rgba(255, 0, 0, 1)",
                          border: "1px solid rgba(255, 0, 0, 1)",
                          borderColor: "rgba(255, 0, 0, 1)",
                          fontSize: "0.8rem",
                          display: "inline-block",
                          textAlign: "center",
                        }}
                        className="badge badge-danger"
                      >
                        {item.status}
                      </label>
                    ) : item.status !== undefined && item.status === "" ? (
                      <label
                        style={{
                          padding: "8px",
                          borderRadius: ".4rem",
                          width: "100px",
                          backgroundColor: "rgba(255, 0, 0, 0.1)",
                          color: "rgba(255, 0, 0, 1)",
                          border: "1px solid rgba(255, 0, 0, 1)",
                          borderColor: "rgba(255, 0, 0, 1)",
                          fontSize: "0.8rem",
                          display: "inline-block",
                          textAlign: "center",
                        }}
                        className="badge badge-danger"
                      >
                        {"Failed"}
                      </label>
                    ) : (
                      <label
                        style={{
                          padding: "8px",
                          borderRadius: ".4rem",
                          width: "100px",
                          backgroundColor: "rgba(255, 238, 0, 0.1)",
                          color: "rgba(255, 196, 0, 1)",
                          border: "1px solid rgba(255, 238, 0, 1)",
                          borderColor: "rgba(255, 238, 0, 1)",
                          fontSize: "0.8rem",
                          display: "inline-block",
                          textAlign: "center",
                        }}
                        className="badge badge-warning"
                      >
                        {item.status}
                      </label>
                    )}
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
        {data.length <= 0 && <NoDataFound />}

        <Pagination
          page={currentPage}
          limit={pages}
          callback={(page, offsetPassed) => {
            if (currentPage != page) setCurrentPage(page);
            if (offset != offsetPassed) setOffset(offsetPassed);
          }}
          count={dataToDisplay.length}
          total={total}
        />
      </div>
    </>
  );
};

export default SuspectedReport;

export const NoDataFound = () => {
  return (
    <div className="d-flex flex-column align-items-center justify-content-center p-3">
      <Lottie
        animationData={ComingSoonImage}
        alt="Coming Soon"
        style={{ height: "40vh", width: "100vw" }}
      />
      <strong>No Data Found</strong>
    </div>
  );
};
