import React, { useEffect, useState } from "react";
import PDF from "../../assets/images/PDF.svg";
import axios from "axios";

const AllApiDocs = () => {
  const [pdfData, setPdfData] = useState([]);

  const pdfapi = async () => {
    try {
      const requestBody = {
        merchantId: "2",
      };
      const response = await axios.post(
        "https://bumppy.in/bumppy.in/payin/api/getApiDocs.php",
        requestBody
      );

      if (response.data.status === "00") {
        setPdfData(response.data.docs);
      }
    } catch (error) {
      console.error("Error fetching PDF data:", error);
    }
  };

  useEffect(() => {
    pdfapi();
  }, []);

  const handleDownload = (url) => {
    window.open(url, "_blank");
  };

  return (
    <>
      <div className="row col-12 d-flex justify-content-center">
        {pdfData.map((item, index) => (
          <div key={index} className="col-lg-3 col-12 card p-4 m-4 bg-light ">
            <div className="d-flex justify-content-center align-item-center">
              <div>
                <img
                  src={PDF}
                  alt=""
                  height={100}
                  style={{ objectFit: "fit" }}
                />
              </div>
              <div>
                <div>{item.doc_name}</div>
                <div>{item.indate}</div>
                <div className="mt-2">
                  <button
                    className="btn px-2 btn-primary"
                    onClick={() => handleDownload(item.doc_url)}
                  >
                    View
                  </button>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default AllApiDocs;