import React, { useState, useEffect, useContext } from "react";
import { Button, Form } from "react-bootstrap";
import DatePicker from "react-datepicker";
import {
  excelMapping,
  ExportToExcel,
  now,
  twoDecimalPlaces,
} from "../utils/utilFuctions";
import {Pagination} from '../utils/Pagination';

export const columns = [
  {
    dataField: "id",
    text: "Txnt ID",
    color: "#586275",
  },
  {
    dataField: "opening_bal",
    text: "Opening Balance",
    color: "#586275",
  },
  {
    dataField: "payout_today",
    text: "Payout Today",
    color: "#586275",
  },
  {
    dataField: "payout_fee",
    text: "Payout Charges",
    color: "#586275",
  },
  {
    dataField: "today_topup",
    text: "Topup Today",
    color: "#586275",
  },
  {
    dataField: "today_refund",
    text: "Refund Today",
    color: "#586275",
  },
  {
    dataField: "settlement_today",
    text: "Settlement Today",
    color: "#586275",
  },
  {
    dataField: "payout_fee",
    text: "Payout Charges",
    color: "#586275",
  },
  {
    dataField: "payin_fee",
    text: "Payin Charges",
    color: "#586275",
  },
  {
    dataField: "closingBal",
    text: "Closing Balance",
    color: "#586275",
  },
  {
    dataField: "datevalue",
    text: "Date",
    color: "#586275",
  },
];

export const Ledger = ({ setDetails }) => {
  const [offset, setOffset] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [dataToDisplay, setDataToDisplay] = useState([]);
  const [pages, setPages] = useState(0);
  const [total, setTotal] = useState(0);
  const [startDate, setStartDate] = useState(getDatePreviousMonth());
  const [endDate, setEndDate] = useState(new Date());

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  function getDatePreviousMonth(date = new Date()) {
    date.setMonth(date.getMonth() - 1);
    return date;
  }
  const dateFormatter = (date = new Date()) => {
    const mm = date.getMonth() + 1;
    return `${date.getFullYear()}-${mm > 9 ? mm : "0" + mm}-${date.getDate() > 9 ? date.getDate() : "0" + date.getDate()
      }`;
  };
  const getAllVirtualPayments = async () => {
    const accessToken = sessionStorage.getItem("accessToken");
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${accessToken}`);
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Cookie",
      ".AspNetCore.Identity.Application=CfDJ8Knu5GvxZz1PqShq3i6d-iwOgwEj6EBN0jpchgFhuiV_O6Tcuf6QLM3cjarWosB3o17JTbrLuvcOmIJGLw0OwV-JJr2mhON-MIsfNGVplhmoJMIoQQrIyv6dzlCW8_eOvZw0ahT_icJcGsoHfR-1FLppZoY2SWzX8NUi9aabidVqbnsJjig4BpI2oPpnu73FH5iMh2Kfuz2xWcPSdx08C7D_6Ygts4I9XapyCvm0OVKguJ3fWPFHBgoY-PSnQ4KNzeh-OI4TzaffhkWCBwgBLtA6ybohQ1cqY5c2Y2EPo-sgAft8xvCDg9pibGVqcUdtEkqHj-BKKBdLijXEW-P7Q9e-T-wL07LWdnOnMPF9YCns79c8kBgyHTiVi82lFdqqIZtzZnhJsZoVhf9vsGaD37-6zhe4sDOkUJf4WCLur6gGQB8LQrfK9RZg30X0OvMv7ZCOtC4SKxgXiyk44NtkqY9oP_cVSb00F_E80WkQhk1A4nUfPalYivPP3EPF45TtK2hlhfdvdw_G1rMLrDiTbaIj-yvW5831ualRbsVUhVSHiUZzLTshMjUg-r_o5FNk4OSR2EyJm2l1WGwLdtZccqzvuyG_UzV9XiIYA658Nqy0iQX-ckKK4yR0yLPGXkmyx1MzMh9RjVPJwsRmhnIKqPE4eOFQNdJDYowJQhEQIMZV"
    );

    var raw = JSON.stringify({
      fromDate: dateFormatter(startDate),
      toDate: dateFormatter(endDate),
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    try {

      setLoading(true);
      // setTransactionLoaderState(true)
      const res = await fetch(
        "https://apitranxt.paynnow.com/api/Payments/GetUserledger",
        requestOptions
      );
      console.log("response", res);
      if (res.status == 41) {
        //   removeMyAccountInfo();
      } else if (res.ok) {

        setLoading(false);
        const data = await res.json();
        console.log(data);
        if (data && data.length > 0) {
          const dataLoad = data.map((acc) => {
            return {
              ...acc,
              upi: `${acc.beneAccountNo}@yesbankltd`,
              date: new Date(acc.createdOn),
              closingBal: twoDecimalPlaces(
                Number(acc.opening_bal) +
                Number(acc.today_topup) +
                Number(acc.today_refund) -
                Number(acc.payout_today) -
                Number(acc.payout_fee) -
                Number(acc.payin_fee) +
                Number(acc.settlement_today)
              ),
            };
          });
          dataLoad.sort(function (a, b) {
            return new Date(b.date) - new Date(a.date);
          });
          console.log(dataLoad);
          setData(dataLoad);
          setDataToDisplay(dataLoad);
        }
      }
      // setTransactionLoaderState(false)
      // setPayments(JSON.parse(body).data);
    } catch (error) {
      // setTransactionLoaderState(false)
      setLoading(false);
      console.log("41 catched", error);
    }
  };

  const openInNewTab = () => {
    const newWindow = window.open(
      `/pages/print?id=090422191784660&type=DMT`,
      "",
      "height=600,width=800'"
    );
    if (newWindow) newWindow.opener = null;
  };

  useEffect(() => {
    let filteredData = data.filter((bank) => {
      let b = false;
      if (data.length > 0) {
        for (let key in data[0]) {
          if (
            bank[key] &&
            bank[key]
              .toString()
              .toLowerCase()
              .includes(searchText.toString().toLowerCase())
          ) {
            b = true;
          }
        }
      }
      return b;
    });
    setPages(Math.floor((filteredData.length + (offset - 1)) / offset));
    setTotal(filteredData.length);
    const startIdx = 0;
    const endIdx = offset - 1;
    filteredData = filteredData.filter((bank, idx) => {
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
    setCurrentPage(1);
  }, [searchText]);

  useEffect(() => {
    let filteredData = data.filter((bank) => {
      let b = false;
      if (data.length > 0) {
        for (let key in data[0]) {
          if (
            bank[key] &&
            bank[key]
              .toString()
              .toLowerCase()
              .includes(searchText.toString().toLowerCase())
          ) {
            b = true;
          }
        }
      }
      return b;
    });
    console.log(filteredData);
    const startIdx = (currentPage - 1) * offset;
    const endIdx = currentPage * offset - 1;
    console.log({ startIdx, endIdx });
    filteredData = filteredData.filter((bank, idx) => {
      console.log(idx, idx <= endIdx && idx >= startIdx);
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
    console.log({ startIdx, endIdx });
  }, [currentPage]);

  // did mount
  useEffect(() => {
    setPages(Math.floor((data.length + (offset - 1)) / offset));
    const startIdx = 0;
    const endIdx = offset - 1;
    setTotal(data.length);
    console.log(startIdx);
    console.log(data);
    const filteredData = data.filter((bank, idx) => {
      return idx <= endIdx && idx >= startIdx;
    });
    console.log(filteredData);
    setDataToDisplay(filteredData);
  }, [data, offset]);

  // did mount
  useEffect(() => {
    getAllVirtualPayments();
  }, []);
  console.log(data);
  return (
    <div style={{ background: "white" }}>
      <div className="row">
        <h4 className="col m-2">
          Ledger
        </h4>

        <div className=" col m-2 daterange-picker">
          <div className="row">
            <Form.Group className="col">
              <Form.Control
                type="text"
                className="form-control rounded-0"
                aria-label="Notes"
                aria-describedby="basic-addon1"
                placeholder="Search"
                onChange={(e) => {
                  setSearchText(e.target.value);
                }}
                value={searchText}
              />
            </Form.Group>
            <Form.Group className="col" style={{ marginLeft: "10px", width: "8rem" }}>
              <DatePicker
                dateFormat="dd/MM/yyyy"
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                className="form-control"
                placeholderText="Start Date"
                style={{ borderRadius: "0px" }}
              />
            </Form.Group>

            <Form.Group className="col" style={{ marginLeft: "10px", width: "8rem" }}>
              <DatePicker
                dateFormat="dd/MM/yyyy"
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                className="form-control"
                placeholderText="End Date"
                style={{ borderRadius: "0px" }}
              />
            </Form.Group>
            <div className="col" style={{ marginLeft: "10px" }}>
              {" "}
              <Button
                className=""
                style={{ borderRadius: ".4rem", width: "7rem" }}
                variant="primary"
                onClick={getAllVirtualPayments}
              >
                {"Search"}
              </Button>
            </div>
            <div
              className="d-flex"
              style={{
                cursor: "pointer",
                border: "1px solid",
                height: "2.5rem",
                width: "11rem",
                backgroundColor: "rgba(43, 193, 85, 0.1)",
                color: "rgba(43, 193, 85, 1)",
                bordercolor: "rgba(43, 193, 85, 1)",
                borderRadius: ".4rem",
              }}
              onClick={() => {
                ExportToExcel(
                  `LEDGER_REPORT_${now()}`,
                  excelMapping(data, columns)
                );
              }}
            >
              {/* <img
                alt=""
                src={require("../../assets/images/excel.png")}
                style={{ width: "1.6rem", marginLeft: "15px" }}
              /> */}
              <strong
                className="d-flex align-items-center"
                style={{ marginLeft: "1rem" }}
              >
                Export to Excel
              </strong>
            </div>
          </div>
        </div>
      </div>

      <div className=" card  center-block scroll-inner">
        <table
          className="dashboard-table-style"
          style={{ width: "100%", overflowX: "auto" }}
        >
          <tr className="m-3" style={{ height: "3rem" }}>
            <th
              // style={{ borderRadius: "15px 0px 0px 0px" }}
              className=" text-white text-center py-3 "
              style={{ background: "#2c3e50" }}
            >
              {" "}
              {"Sr"}
            </th>
            {columns.map((heading, index) => (
              <th
                key={index}
                className=" text-white text-center py-3 "
                style={{ background: "#2c3e50" }}
              >
                {heading.text}
              </th>
            ))}
          </tr>
          {loading && (
            <div className="loader-container d-flex justify-content-center align-items-center">
              <div className="loader"></div>
            </div>
          )}
          {dataToDisplay.map((e, ind) => (
            <tr style={{ height: "3rem" }} key={ind}>
              <td className="text-center" style={{ fontSize: ".8rem" }}>{ind + 1}</td>
              {columns.map((cell) => (
                <td className="text-center"
                  key={cell.dataField}
                  style={{ fontSize: ".8rem", color: cell.color, }}
                >
                  {cell.dataField === "status" && (
                    <>
                      {(e[cell.dataField].toString().toLowerCase() ===
                        "refunded" ||
                        e[cell.dataField].toString().toLowerCase() ===
                        "refund") && (
                          <label
                            style={{
                              padding: "8px",
                              borderRadius: ".4rem",
                              backgroundColor: "rgba(0, 123, 255, 0.1)",
                              color: "rgba(0, 123, 255, 1)",
                              border: "1px solid rgba(0, 123, 255, 1)",
                              borderColor: "rgba(0, 123, 255, 1)",
                              fontSize: ".8rem",
                            }}
                            className="badge badge-warning"
                          >
                            {e[cell.dataField]}
                          </label>
                        )}
                      {e[cell.dataField] === "SettlementCompleted" && (
                        <label
                          style={{
                            padding: "8px",
                            border: "1px solid",
                            height: "2rem",
                            backgroundColor: "rgba(43, 193, 85, 0.1)",
                            color: "rgba(43, 193, 85, 1)",
                            borderColor: "rgba(43, 193, 85, 1)",
                            borderRadius: ".4rem",
                            fontSize: ".8rem",
                          }}
                          className="badge badge-success"
                        >
                          {e[cell.dataField]}
                        </label>
                      )}
                      {(e[cell.dataField] === "Received" ||
                        e[cell.dataField] === "Accepted") && (
                          <label
                            style={{
                              padding: "8px",
                              borderRadius: ".4rem",
                              backgroundColor: "rgba(0, 123, 255, 0.1)",
                              color: "rgba(0, 123, 255, 1)",
                              border: "1px solid rgba(0, 123, 255, 1)",
                              borderColor: "rgba(0, 123, 255, 1)",
                              fontSize: ".8rem",
                            }}
                            className="badge badge-warning"
                          >
                            {e[cell.dataField]}
                          </label>
                        )}
                      {e[cell.dataField].toString().toLowerCase() ===
                        "failed" && (
                          <label
                            style={{
                              padding: "8px",
                              borderRadius: ".4rem",
                              backgroundColor: "rgba(0, 123, 255, 0.1)",
                              color: "rgba(0, 123, 255, 1)",
                              border: "1px solid rgba(0, 123, 255, 1)",
                              borderColor: "rgba(0, 123, 255, 1)",
                              fontSize: ".8rem",
                            }}
                            className="badge badge-danger"
                          >
                            Failed
                          </label>
                        )}
                      {e[cell.dataField] === "0" && (
                        <label
                          style={{
                            padding: "8px",
                            borderRadius: ".4rem",
                            backgroundColor: "rgba(0, 123, 255, 0.1)",
                            color: "rgba(0, 123, 255, 1)",
                            border: "1px solid rgba(0, 123, 255, 1)",
                            borderColor: "rgba(0, 123, 255, 1)",
                            fontSize: ".8rem",
                          }}
                          className="badge badge-warning"
                        >
                          Virtual
                        </label>
                      )}
                    </>
                  )}
                  {cell.dataField !== "status" && e[cell.dataField]}
                </td>
              ))}
            </tr>
          ))}
        </table>
      </div>
      <Pagination
        page={currentPage}
        limit={pages}
        callback={(page,offsetPassed) => {
          if(currentPage!=page)
            setCurrentPage(page);
        if(offset!= offsetPassed)
          setOffset(offsetPassed)
        }}
        count={dataToDisplay.length}
        total={total}
      />
    </div>
  );
};
