import React, { useEffect, useState } from "react";
import { Badge, Button, Modal, Form, FormControl } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import Swal from "sweetalert2";
import { Link, useLocation } from "react-router-dom";
const arr = [{ label: "Name", value: "Name" }];

const SetMerchantCommercials = () => {
  const [data, setData] = useState([]);
  const [editableData, setEditableData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedTransactionType, setSelectedTransactionType] = useState("");
  const [validRateType, setValidRateType] = useState("defaultRateType");
  const [events, setEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState("");
  const [loading, setLoading] = useState(false);

  const location = useLocation();
  const merchantId = new URLSearchParams(location.search).get("id");

  const [formData, setFormData] = useState({
    commercialType: "",
    toAmount: "",
    fromAmount: "",
    cgst: "",
    sgst: "",
    charges: "",
    commission: "",
    level1Commission: "",
    level2Commission: "",
    rateType: "",
  });
  const [errors, setErrors] = useState({
    commercialType: "",
    toAmount: "",
    fromAmount: "",
    cgst: "",
    sgst: "",
    charges: "",
    commission: "",
    level1Commission: "",
    level2Commission: "",
    rateType: "",
  });
  const validate = (e) => {
    e.preventDefault();
    console.log(" validate function");
    console.log(formData);
    let errors = {};
    if (formData.commercialType.trim() == "") {
      errors.commercialType = "*Please enter commercial Type";
    }
    if (formData.toAmount.trim() == "") {
      errors.toAmount = "*Please enter to Amount";
    }
    if (formData.fromAmount.trim() == "") {
      errors.fromAmount = "*Please enter from Amount";
    }
    if (formData.cgst.trim() == "") {
      errors.cgst = "*Please enter cgst";
    }
    if (formData.sgst.trim() == "") {
      errors.sgst = "*Please enter sgst";
    }
    if (formData.charges.trim() == "") {
      errors.charges = "*Please enter charges";
    }
    if (formData.commission.trim() == "") {
      errors.commission = "*Please select commission";
    }
    if (formData.level1Commission.trim() == "") {
      errors.level1Commission = "*Please enter level1Commission";
    }
    if (formData.level2Commission.trim() == "") {
      errors.level2Commission = "*Please select level2Commission";
    }
    if (formData.rateType.trim() == "") {
      errors.rateType = "*Please select rateType";
    }

    setErrors(errors);
    console.log("Validation Errors:", errors);
    console.log("handle change button 1 ");
    if (Object.keys(errors).length == 0) {
      handleSubmit();
      console.log("handle change button n ");
    }
  };

  console.log("merchantId", merchantId);

  const handleSubmit = async () => {
    try {
      const apiHeaders = new Headers();
      apiHeaders.append("Content-Type", "application/json");

      const apiBody = JSON.stringify({
        merchantId: merchantId,
        merchantSecret: "3DjyvAQvTZlk86g5jvHI7dfS",
        commercialType: selectedTransactionType,
        toAmount: parseFloat(formData.toAmount),
        fromAmount: parseFloat(formData.fromAmount),
        cgst: parseFloat(formData.cgst),
        sgst: parseFloat(formData.sgst),
        charges: parseFloat(formData.charges),
        commission: parseFloat(formData.commission),
        level1Commission: parseFloat(formData.level1Commission),
        level2Commission: parseFloat(formData.level2Commission),
        rateType: validRateType,
      });

      const apiRequestOptions = {
        method: "POST",
        headers: apiHeaders,
        body: apiBody,
        redirect: "follow",
      };

      const apiResponse = await fetch(
        "https://bumppy.in/payin/api/addMerchantCommercials.php",
        apiRequestOptions
      );
      const responseData = await apiResponse.json();

      if (responseData.status === "00") {
        Swal.fire("Success", "Merchant Commercial Added Successfully");
        setShowModal(false);
        getAllPropertyAccountLedger();
      } else {
        console.error("API Error:", responseData.message);
        Swal.fire({
          icon: "error",
          title: data.msg,
          text: responseData.message,
        });
      }
    } catch (error) {
      console.error("API Request Error:", error);
    }
  };

  const getAllPropertyAccountLedger = async () => {
    try {
      setLoading(true);
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        merchantId: merchantId,
        merchantSecret: "3DjyvAQvTZlk86g5jvHI7dfS",
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `https://bumppy.in/payin/api/getAllMerchantCommercials.php`,
        requestOptions
      );
      const apiResponse = await res.json();
      if (apiResponse.status === "00") {
        const updatedEditableData = apiResponse.data.map((item) => ({
          ...item,
          pk_rate_id: item.pk_rate_id, // Add the primary key field
        }));
        setData(apiResponse.data);
        setEditableData(updatedEditableData);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log("error", error);
    }
  };

  const handleDelete = async (pkRateId) => {
    try {
      const isConfirmed = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      });

      if (isConfirmed.isConfirmed) {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
          merchantId: merchantId,
          merchantSecret: "3DjyvAQvTZlk86g5jvHI7dfS",
          rateId: pkRateId,
        });

        var requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: raw,
          redirect: "follow",
        };

        const deleteRes = await fetch(
          "https://bumppy.in/payin/api/deleteMerchantCommercials.php",
          requestOptions
        );
        const deleteData = await deleteRes.json();
        if (deleteData.status === "00") {
          Swal.fire("Success", "Merchant Commercial Deleted Successfully");
          getAllPropertyAccountLedger();
        } else {
          Swal.fire("Error", "Something went wrong");
        }
      }
    } catch (error) {
      console.error("Delete Error:", error);
    }
  };

  const handleEditChange = (index, field, value) => {
    const newData = [...editableData];
    newData[index][field] = isNaN(parseFloat(value))
      ? value
      : parseFloat(value).toFixed(2);
    setEditableData(newData);
  };
  const handleUpdate = async (pkRateId, updatedData) => {
    try {
      setLoading(true);
      const updateHeaders = new Headers();
      updateHeaders.append("Content-Type", "application/json");

      const updateBody = JSON.stringify({
        merchantId: merchantId,
        merchantSecret: "3DjyvAQvTZlk86g5jvHI7dfS",
        rateId: pkRateId,
        toAmount: parseFloat(updatedData.to_amt),
        fromAmount: parseFloat(updatedData.from_amt),
        cgst: parseFloat(updatedData.cgst),
        sgst: parseFloat(updatedData.sgst),
        charges: parseFloat(updatedData.charges),
        commission: parseFloat(updatedData.commission),
        level1Commission: parseFloat(updatedData.lvl_1_comm),
        level2Commission: parseFloat(updatedData.lvl_2_comm),
        rateType: updatedData.rate_type,
      });

      const updateRequestOptions = {
        method: "POST",
        headers: updateHeaders,
        body: updateBody,
        redirect: "follow",
      };

      const updateRes = await fetch(
        "https://bumppy.in/payin/api/updateMerchantCommercials.php",
        updateRequestOptions
      );
      const updateData = await updateRes.json();
      if (updateData.status === "00") {
        Swal.fire("Success", "Merchant Commercial Updated Successfully");
        getAllPropertyAccountLedger();
      } else {
        Swal.fire("Error", "Something went wrong");
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Update Error:", error);
    }
  };

  const ViewAllCommercialEvents = async () => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        merchantId: merchantId,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `https://bumppy.in/payin/api/getAllTransactionType.php`,
        requestOptions
      );
      const eventsData = await res.json();
      console.log("dhdh", eventsData);
      if (eventsData.status === "00") {
        setEvents(eventsData.data);
      } else {
        console.error("Error fetching events:", eventsData.message);
      }
    } catch (error) {
      console.error("Error fetching events:", error);
    }
  };

  const handleAddCommercialClick = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    getAllPropertyAccountLedger();
    ViewAllCommercialEvents();
  }, []);

  console.log(selectedTransactionType);

  return (
    <>
      {loading && (
        <div className="loader-container">
          <div className="loader"></div>
          <div className="loader-text">Please wait...</div>
        </div>
      )}
      <div className="p-2 ">
        <div className="row m-3">
          <h5 className="col">View All Commercial</h5>
          <div className=" col  d-flex justify-content-end">
            <Button onClick={handleAddCommercialClick}>Add Commercial</Button>
          </div>
        </div>
        <div
          className=" bg-white "
          style={{ border: "1px solid rgba(0,0,0,1)", borderRadius: "15px" }}
        >
          <table className="table" style={{ fontFamily: "poppins" }}>
            <thead>
              <tr>
                <th
                  style={{ borderRadius: "15px 0px 0px 0px" }}
                  className=" text-white text-center py-3 bg-primary"
                >
                  User Id{" "}
                </th>

                <th className="bg-primary text-white text-center py-3">
                  From Amt
                </th>
                <th className="bg-primary text-white text-center py-3">
                  To Amt
                </th>
                <th className="bg-primary text-white text-center py-3">SGST</th>
                <th className="bg-primary text-white text-center py-3">CGST</th>
                <th className="bg-primary text-white text-center py-3">
                  Charges
                </th>
                <th className="bg-primary text-white text-center py-3">
                  Commission
                </th>
                <th className="bg-primary text-white text-center py-3">
                  Lvl1
                  <br /> Commission
                </th>
                <th className="bg-primary text-white text-center py-3">
                  Lvl2
                  <br /> Commission
                </th>
                <th className="bg-primary text-white text-center py-3">
                  Rate typ
                </th>
                <th className="bg-primary text-white text-center py-3">
                  CreatedOn
                </th>
                <th className="bg-primary text-white text-center py-3">
                  UpdatedOn
                </th>
                <th
                  style={{ borderRadius: "0px 15px 0px 0px" }}
                  className="bg-primary text-center py-3 text-white text-center py-3"
                >
                  {" "}
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {editableData.map((item, index) => (
                <tr key={item.id}>
                  <td className="text-center align-middle">
                    {parseFloat(item.fk_user_id).toFixed(2)}
                  </td>
                  <td
                    className="text-center align-middle"
                    contentEditable
                    onBlur={(e) =>
                      handleEditChange(index, "from_amt", e.target.textContent)
                    }
                  >
                    {parseFloat(item.from_amt).toFixed(2)}
                  </td>
                  <td
                    className="text-center align-middle"
                    contentEditable
                    onBlur={(e) =>
                      handleEditChange(index, "to_amt", e.target.textContent)
                    }
                  >
                    {parseFloat(item.to_amt).toFixed(2)}
                  </td>
                  <td
                    className="text-center align-middle"
                    contentEditable
                    onBlur={(e) =>
                      handleEditChange(index, "sgst", e.target.textContent)
                    }
                  >
                    {parseFloat(item.sgst).toFixed(2)}
                  </td>
                  <td
                    className="text-center align-middle"
                    contentEditable
                    onBlur={(e) =>
                      handleEditChange(index, "cgst", e.target.textContent)
                    }
                  >
                    {parseFloat(item.cgst).toFixed(2)}
                  </td>
                  <td
                    className="text-center align-middle"
                    contentEditable
                    onBlur={(e) =>
                      handleEditChange(index, "charges", e.target.textContent)
                    }
                  >
                    {parseFloat(item.charges).toFixed(2)}
                  </td>
                  <td
                    className="text-center align-middle"
                    contentEditable
                    onBlur={(e) =>
                      handleEditChange(
                        index,
                        "commission",
                        e.target.textContent
                      )
                    }
                  >
                    {parseFloat(item.commission).toFixed(2)}
                  </td>
                  <td
                    className="text-center align-middle"
                    contentEditable
                    onBlur={(e) =>
                      handleEditChange(
                        index,
                        "lvl_1_comm",
                        e.target.textContent
                      )
                    }
                  >
                    {parseFloat(item.lvl_1_comm).toFixed(2)}
                  </td>
                  <td
                    className="text-center align-middle"
                    contentEditable
                    onBlur={(e) =>
                      handleEditChange(
                        index,
                        "lvl_2_comm",
                        e.target.textContent
                      )
                    }
                  >
                    {parseFloat(item.lvl_2_comm).toFixed(2)}
                  </td>
                  <td
                    className="text-center align-middle"
                    contentEditable
                    onBlur={(e) =>
                      handleEditChange(index, "rate_type", e.target.textContent)
                    }
                  >
                    {item.rate_type}
                  </td>
                  <td
                    className="text-center align-middle"
                    contentEditable
                    onBlur={(e) =>
                      handleEditChange(index, "createdOn", e.target.textContent)
                    }
                  >
                    {new Date(item.createdOn).toLocaleDateString()}
                  </td>
                  <td
                    className="text-center align-middle"
                    contentEditable
                    onBlur={(e) =>
                      handleEditChange(index, "updatedOn", e.target.textContent)
                    }
                  >
                    {new Date(item.updatedOn).toLocaleDateString()}
                  </td>
                  <td className="text-center align-middle">
                    <span
                      role="img"
                      aria-label="Edit"
                      style={{ cursor: "pointer" }}
                      onClick={() => handleUpdate(item.pk_rate_id, item)}
                    >
                      ✎
                    </span>
                    <span
                      role="img"
                      aria-label="Delete"
                      style={{ cursor: "pointer", marginLeft: "10px" }}
                      onClick={() => handleDelete(item.pk_rate_id)}
                    >
                      🗑
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Add Commercial</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <div className="row">
              {" "}
              <Form.Group className="col" controlId="event">
                <Form.Label>Transaction Type</Form.Label>
                <Form.Control
                  as="select"
                  value={selectedTransactionType}
                  onChange={(e) => setSelectedTransactionType(e.target.value)}
                >
                  {events.map((event) => (
                    <option
                      key={event.TransactionTypeId}
                      value={event.TransactionTypeId}
                    >
                      {event.Transactiontype}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
              <Form.Group className="col" controlId="fromAmount">
                <Form.Label>From Amount</Form.Label>
                <FormControl
                  type="text"
                  placeholder="Enter From Amount"
                  value={formData.fromAmount}
                  onChange={(e) =>
                    setFormData({ ...formData, fromAmount: e.target.value })
                  }
                />
                <Form.Text className="text-danger">
                  {errors.fromAmount}
                </Form.Text>
              </Form.Group>
            </div>

            <div className="row">
              <Form.Group className="col" controlId="toAmount">
                <Form.Label>To Amount</Form.Label>
                <FormControl
                  type="text"
                  placeholder="Enter To Amount"
                  value={formData.toAmount}
                  onChange={(e) =>
                    setFormData({ ...formData, toAmount: e.target.value })
                  }
                />
                <Form.Text className="text-danger">{errors.toAmount}</Form.Text>
              </Form.Group>

              <Form.Group className="col" controlId="cgst">
                <Form.Label>CGST</Form.Label>
                <FormControl
                  type="text"
                  placeholder="Enter From cgst"
                  value={formData.cgst}
                  onChange={(e) =>
                    setFormData({ ...formData, cgst: e.target.value })
                  }
                />
                <Form.Text className="text-danger">{errors.cgst}</Form.Text>
              </Form.Group>
            </div>

            <div className="row">
              {" "}
              <Form.Group className="col" controlId="sgst">
                <Form.Label>SGST</Form.Label>
                <FormControl
                  type="text"
                  placeholder="Enter sgst"
                  value={formData.sgst}
                  onChange={(e) =>
                    setFormData({ ...formData, sgst: e.target.value })
                  }
                />
                <Form.Text className="text-danger">{errors.sgst}</Form.Text>
              </Form.Group>
              <Form.Group className="col" controlId="charges">
                <Form.Label>charges</Form.Label>
                <FormControl
                  type="text"
                  placeholder="Enter charges"
                  value={formData.charges}
                  onChange={(e) =>
                    setFormData({ ...formData, charges: e.target.value })
                  }
                />
                <Form.Text className="text-danger">{errors.charges}</Form.Text>
              </Form.Group>
            </div>
            <div className="row">
              <Form.Group className="col" controlId="commission">
                <Form.Label>commission</Form.Label>
                <FormControl
                  type="text"
                  placeholder="Enter commission"
                  value={formData.commission}
                  onChange={(e) =>
                    setFormData({ ...formData, commission: e.target.value })
                  }
                />
                <Form.Text className="text-danger">
                  {errors.commission}
                </Form.Text>
              </Form.Group>

              <Form.Group className="col" controlId="level1Commission">
                <Form.Label>Level 1 Commission</Form.Label>
                <FormControl
                  type="text"
                  placeholder="Enter level 1 Commission"
                  value={formData.level1Commission}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      level1Commission: e.target.value,
                    })
                  }
                />
                <Form.Text className="text-danger">
                  {errors.level1Commission}
                </Form.Text>
              </Form.Group>
            </div>
            <div className="row">
              <Form.Group className="col" controlId="level2Commission">
                <Form.Label>Level 2 Commission</Form.Label>
                <FormControl
                  type="text"
                  placeholder="Enter level 2 Commission"
                  value={formData.level2Commission}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      level2Commission: e.target.value,
                    })
                  }
                />
                <Form.Text className="text-danger">
                  {errors.level2Commission}
                </Form.Text>
              </Form.Group>

              <Form.Group className="col" controlId="rateType">
                <Form.Label>Rate Type</Form.Label>
                <Form.Control
                  as="select"
                  value={validRateType}
                  onChange={(e) => setValidRateType(e.target.value)}
                >
                  <option value="defaultRateType">Default Rate Type</option>
                  <option value="F">Fixed</option>
                  <option value="P">Percent</option>
                </Form.Control>
                <Form.Text className="text-danger">{errors.rateType}</Form.Text>
              </Form.Group>
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleSubmit}>
            Add
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default SetMerchantCommercials;
