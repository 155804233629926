import React from "react";
import { useAuth } from "./context/AuthContext";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Login from "./components/Login";

import NavigationBar from "./components/Navbar";
import Report from "./components/Report";
import Pay from "./components/Pay";
import ReportSettlement from "./components/Report Settlement";
import SettlementProcess from "./components/SettlementProcess";
import SettlementPay from "./components/SettlementPay";
import LoadRequest from "./components/LoadRequest";
import Home from "./components/Home";
import CreateMerchant from "./components/merchant/CreateMerchant";
import ViewAllMerchant from "./components/merchant/ViewAllMerchant";
import SetMerchantCommercials from "./components/merchant/SetMerchantCommercials";
import ViewAllWebhooks from "./components/developer/ViewAllWebhooks";
import ViewAllIpList from "./components/developer/ViewAllIpList";
import Dashboard from "./components/dashboard/Dashboard";
import DecodeQR from "./components/api/DecodeQR";
import UPIIntentReport from "./components/UpiIntentReport";
import Developer from "./components/api_partner/Developer2";
import MerchantPayinLedgerReport from "./components/MerchantPayinLedgerReport";
import AllApiDocs from "./components/api_doc/AllApiDocs";
import TransactionLoader from "./components/utils/TransactionLoader";
import PayinReport from "./components/PayinReport";
import TransactionDetails from "./components/transaction/TransactionDetails";
import PayinReport2 from "./components/PayinReport2";
import PayinReport3 from "./components/PayinReport3";
import ApiReference from "./components/developer/ApiReference";

const App = () => {
  const { user, login , loaderState } = useAuth();

  // console.log(user.userType);

  return (
    <BrowserRouter>
      {user ? (
        <>
          <NavigationBar />
          <TransactionLoader isActive={loaderState} text="Loading...">

     
          <Switch>
            <Route path="/Report" component={Report} />
            <Route path="/Home" component={Dashboard} />
            <Route path="/dashboard" component={Dashboard} />
            <Route path="/merchant/create-merchant" component={CreateMerchant} />
            <Route path="/merchant/view-merchant" component={ViewAllMerchant} />
            <Route path="/merchant/set-commercials" component={SetMerchantCommercials} />
            <Route path="/developer/view-webhooks" component={ViewAllWebhooks} />
            <Route path="/developer/view-ip" component={ViewAllIpList} />
            <Route path="/developer/Api-Referance" component={ApiReference} />
            <Route path="/developer/upi-intent-report" component={PayinReport} />
            <Route path="/developer/upi-intent-report-2" component={PayinReport2} />
            <Route path="/developer/upi-intent-report-3" component={PayinReport3} />
            
            <Route path="/developer/payin-ledger-report" component={MerchantPayinLedgerReport} />
            <Route path="/developer/payout" component={Developer} />
            <Route path="/developer/api-docs" component={AllApiDocs} />
            <Route path="/transaction/transaction-details" component={TransactionDetails} />
            
            <Route path="/Pay" component={Pay} />
            <Route path="/ReportSettlement" component={ReportSettlement} />
            <Route
              path="/SettlementProcess"
              component={SettlementProcess}
            />
            <Route path="/SettlementPay" component={SettlementPay} />
            <Route path="/LoadRequest" component={LoadRequest} />
          </Switch>
          </TransactionLoader>
        </>
      ) : (
        <Switch>
                <Route path="/decode" component={DecodeQR} />
          <Route path="/" component={Login} />
        </Switch>
      )}
    </BrowserRouter>
  );
};

export default App;
