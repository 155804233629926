import React, { useEffect, useState } from "react";

import { useAuth } from "../context/AuthContext";
import { ExportToExcel, excelMapping, now } from "./utils/utilFuctions";
import ComingSoonImage from "../assets/animation/Coming Soon (1).json";
import Lottie from "lottie-react";

import { Button, Form } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { SearchOutlined } from "@ant-design/icons";
import swal from "sweetalert";
import Select from "react-select";

import { Pagination } from "./utils/Pagination";
import "./UpiIntentReport.css";
import "react-datepicker/dist/react-datepicker.css";

const arr = [{ label: "Name", value: "Name" }];

const dateBefore1Week = () => {
  const today = new Date();
  const lastWeek = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate() - 7
  );
  return lastWeek;
};

const options = [
  { label: "Success", value: "success" },
  { label: "Failed", value: "n" },
];

export const columns = [
  {
    dataField: "id",
    text: "ID",
    color: "#586275",
  },
  {
    dataField: "fk_user_id",
    text: "User ID",
    color: "#586275",
  },
  {
    dataField: "payin_amount",
    text: "Payin Amount",
    color: "#586275",
  },
  {
    dataField: "payin_charge",
    text: "Payin Charge",
    color: "#586275",
  },
  {
    dataField: "payin_gst",
    text: "Payin GST",
    color: "#586275",
  },
  {
    dataField: "settlement_time",
    text: "Settlement Time",
    color: "#586275",
  },
  {
    dataField: "indate",
    text: "In Date",
    color: "#586275",
  },
  {
    dataField: "status",
    text: "Status",
    color: "#586275",
  },
];

const MerchantPayinLedgerReport = () => {
  const { user } = useAuth();
  const [reportData, setReportData] = useState([]);
  const [startDate, setStartDate] = useState(dateBefore1Week);
  const [endDate, setEndDate] = useState(new Date());
  const [type, setType] = useState("1");
  const [data, setData] = useState([]);
  const [dataToDisplay, setDataToDisplay] = useState([]);
  const [loading, setLoading] = useState(true);

  const [searchText, setSearchText] = useState("");
  const [selectedStatus, setSelectedStatus] = useState(null);

  const [currentPage, setCurrentPage] = useState(1);
  const [offset, setOffset] = useState(10);
  const [total, setTotal] = useState(0);
  const [pages, setPages] = useState(0);

  const [ledger, setLedger] = useState([]);

  const [formData, setFormData] = useState({
    merchantSecret: "",
    ip_address: "",
  });



  const dateFormatter = (d) => {
    const date = new Date(d);
    return `${date.getFullYear()}-${date.getMonth() > 9 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1)
      }-${date.getDate() > 9 ? date.getDate() : "0" + date.getDate()}`;
  };

  useEffect(() => {
    setPages(Math.floor((ledger.length + (offset - 1)) / offset));
    const startIdx = 0;
    const endIdx = offset - 1;
    setTotal(ledger.length);
    const filteredData = ledger.filter((bank, idx) => {
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
  }, [ledger]);

  useEffect(() => {
    let filteredData = data.filter((bank) => {
      let b = false;
      if (data.length > 0) {
        for (let key in data[0]) {
          if (
            bank[key] &&
            bank[key]
              .toString()
              .toLowerCase()
              .includes(searchText.toLowerCase())
          ) {
            b = true;
          }
        }
      }
      return b;
    });
    setPages(Math.floor((filteredData.length + 999) / 1000));
    setTotal(filteredData.length);
    const startIdx = 0;
    const endIdx = 999;
    filteredData = filteredData.filter((bank, idx) => {
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
    setCurrentPage(1);
  }, [searchText]);

  useEffect(() => {
    let filteredData = data.filter((bank) => {
      let b = false;
      if (data.length > 0) {
        for (let key in data[0]) {
          if (
            bank[key] &&
            bank[key]
              .toString()
              .toLowerCase()
              .includes(searchText.toString().toLowerCase())
          ) {
            b = true;
          }
        }
      }
      return b;
    });
    console.log(filteredData);
    const startIdx = (currentPage - 1) * offset;
    const endIdx = currentPage * offset - 1;
    console.log({ startIdx, endIdx });
    filteredData = filteredData.filter((bank, idx) => {
      console.log(idx, idx <= endIdx && idx >= startIdx);
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
    console.log({ startIdx, endIdx });
  }, [currentPage, offset]);

  // did mount
  useEffect(() => {
    setPages(Math.floor((data.length + (offset - 1)) / offset));
    const startIdx = 0;
    const endIdx = offset - 1;
    setTotal(data.length);
    console.log(startIdx);
    console.log(data);
    const filteredData = data.filter((bank, idx) => {
      return idx <= endIdx && idx >= startIdx;
    });
    console.log(filteredData);
    setDataToDisplay(filteredData);
  }, [data, offset]);

  useEffect(() => {
    setPages(Math.floor((ledger.length + (offset - 1)) / offset));
    const startIdx = 0;
    const endIdx = offset - 1;
    setTotal(ledger.length);
    const filteredData = ledger.filter((bank, idx) => {
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
  }, [ledger]);

  const ViewUPIIntentReport = async () => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        merchantId: user.merchantId,
        fromDate: dateFormatter(startDate),
        toDate: dateFormatter(endDate),
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `https://reports.remittpe.com/api/Intent/GetMerchantLedgerReportV2`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        setData(
          data.data.map((e) => ({
            ...e,
            settlementAmount:
              parseFloat(e.payin_amount) -
              parseFloat(e.payin_charge) -
              parseFloat(e.payin_gst),
          }))
        );
        setDataToDisplay(data.data);
      } else {
        setDataToDisplay([])
      }
      setLoading(false);
    } catch (error) {
      console.log("error", error);
      setLoading(false);
    }
  };

  const deleteLedgerEntry = async (id) => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", "Basic cGd1c2VybmV3OlRlc3RAMTIzNDU=");

      var raw = JSON.stringify({
        merchantId: user.merchantId,
        ledgerId: id,
        // "merchantId": "2",
        // "ledgerId": "1"
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `https://reports.remittpe.com/api/Intent/deleteLedgerEntry`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "00") {
        swal("Success", "Ledger Entry Deleted", "success");
        ViewUPIIntentReport()
      }
      setLoading(false);
    } catch (error) {
      console.log("error", error);
      setLoading(false);
    }
  };

  const deleteLedgerEntryVerification = (id) => {
    swal({
      title: "Are you sure?",
      text: `You want to Delete Ledger Entry with ID ${id}`,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        deleteLedgerEntry(id);
      }
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Call the API with the selected date range
    ViewUPIIntentReport();
  };

  function getStatusBadgeClass(status) {
    switch (status) {
      case "SUCCESS":
        return "bg-success text-white";
      case "N":
        return "bg-danger text-white";
      default:
        return "bg-light text-dark";
    }
  }

  useEffect(() => {
    ViewUPIIntentReport();
  }, []);

  useEffect(() => {
    if (selectedStatus) {
      const filteredData = data.filter((e) => {
        return e.status.toString().toLowerCase() == selectedStatus.value;
      });
      setDataToDisplay(filteredData);
    }
  }, [selectedStatus]);

  return (
    <>
      <div className="card shadow rounded m-2" style={{ background: "white" }}>
        <div className="row m-2 p-0 pt-4 align-items-center" id="parentFilters" style={{ background: "white" }}>
          <div
            className="m-0 p-0 pl-2 flex-grow-1 d-flex "
            style={{
              fontFamily: "Poppins",
              fontSize: "32px",
              fontWeight: "600",
              lineHeight: "48px",
              letterSpacing: "0em",
              textAlign: "left",
              padding: "0px",

              color: "#2c3e50",
              flex: 1,
              fontWeight: "bolder",
              fontfamily: "Poppins !impotent",
              fontSize: "28px",
              minWidth: "150px",
            }}
          >
            Payin Ledger Report
          </div>
          <div id="filters" style={{ gap: 3, display: "flex", background: "white" }}>

            <div id="searchBarStyle" className=" bg-light m-1 p-0 ">
              <SearchOutlined
                className=" m-0 p-2 "
                style={{ background: "white", color: "grey" }}
              />
              <input
                type="text"
                className="form-control"
                placeholder="Search text..."
                style={{ border: "none" }}
                onChange={(e) => {
                  setSearchText(e.target.value);
                }}
                value={searchText}
              />
            </div>
            <div className="col p-0 m-0" style={{ minWidth: "120px" }}>
              <Select
                options={options}
                value={selectedStatus}
                onChange={(e) => {
                  setSelectedStatus(e);
                }}
                style={{
                  display: "flex",
                  width: "100%",
                  maxWidth: "50%",
                  backgroundColor: "red",
                  border: "none",
                }}
              />
            </div>
            <Form.Group
              className="p-0 m-0 "
              id="parentDatePicker"
              style={{ minWidth: "250px", display: "flex", gap: 3 }}
            >
              <DatePicker
                className="form-control rounded-1 d-flex flex-grow-1"
                style={{
                  borderColor: "#ccc",
                }}
                selected={startDate}
                onChange={(date) => setStartDate(date)}
              />
              <DatePicker
                className="form-control rounded-1 d-flex flex-grow-1"
                style={{
                  borderColor: "#ccc",
                }}
                selected={endDate}
                onChange={(date) => setEndDate(date)}
              />
            </Form.Group>
            <div className="d-flex align-items-center" id="searchButton">
              <Button
                className="rounded-50 btn btn-lg d-flex flex-grow-1 "
                style={{ fontSize: "0.7rem", background: "#2c3e50" }}
                onClick={ViewUPIIntentReport}
              >
                {"Search"}
              </Button>
            </div> <div
              className=" mb-3 d-flex align-items-center justify-content-center "
              style={{
                cursor: "pointer",
                border: "1px solid",
                marginTop: "15px",
                width: "11rem",
                alignItems: "center",
                backgroundColor: "rgba(43, 193, 85, 0.1)",
                color: "rgba(43, 193, 85, 1)",
                bordercolor: "rgba(43, 193, 85, 1)",
                borderRadius: ".4rem",
                minWidth: "170px",
              }}
              onClick={() => {
                ExportToExcel(
                  `payin_ledger_Report_${now()}`,
                  excelMapping(data, columns)
                );
              }}
            >
              <img
                alt=""
                src={require("../assets/images/excel.png")}
                style={{ width: "1.6rem", marginLeft: "15px" }}
              />
              <strong
                className="d-flex align-items-center m-2 "
                style={{ minWidth: "120px", fontSize: "0.8rem" }}
              >
                Export to Excel
              </strong>
            </div>
          </div>
        </div>
        <div
          className=" bg-white p-2 "
        // style={{ border: "1px solid rgba(0,0,0,1)", borderRadius: "15px" }}
        >
          <table className="table" style={{ fontFamily: "poppins" }}>
            <thead>
              <tr style={{ background: "#2c3e50" }}>
                <th
                  // style={{ borderRadius: "15px 0px 0px 0px" }}
                  className=" text-white text-center py-3 "
                  style={{ background: "#2c3e50" }}
                >
                  {" "}
                  Id
                </th>
                <th className=" text-white text-center py-3 "
                  style={{ background: "#2c3e50" }}
                >
                  Payin Amount
                </th>
                <th className=" text-white text-center py-3"
                  style={{ background: "#2c3e50" }}
                >

                  Payin Charge
                </th>
                <th className=" text-white text-center py-3 "
                  style={{ background: "#2c3e50" }}
                >
                  Payin Gst
                </th>
                <th className=" text-white text-center py-3 " style={{ background: "#2c3e50" }}
                >
                  Settlement Amount
                </th>
                <th className=" text-white text-center py-3 " style={{ background: "#2c3e50" }}
                >
                  Transaction time
                </th>
                <th className=" text-white text-center py-3 " style={{ background: "#2c3e50" }}
                >
                  Settlement time
                </th>
                <th className=" text-white text-center py-3 " style={{ background: "#2c3e50" }}
                >
                  Last Updated
                </th>
                <th className=" text-white text-center py-3" style={{ background: "#2c3e50" }}
                >Status</th>
                <th className=" text-white text-center py-3" style={{ background: "#2c3e50" }}
                >Action</th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr>
                  <td colSpan="9" className="text-center">
                    Loading...
                  </td>
                </tr>
              ) : (
                dataToDisplay.map((item) => (
                  <tr key={item.UserName}>
                    <td className="text-center align-middle">
                      {user.merchantId == 2 ? item.fk_user_id : item.id || "NA"}
                    </td>
                    <td className="text-center align-middle">
                      {item.payin_amount || "NA"}
                    </td>
                    <td className="text-center align-middle">
                      {item.payin_charge !== undefined
                        ? parseFloat(item.payin_charge).toFixed(2)
                        : "NA"}
                    </td>

                    <td className="text-center align-middle">
                      {item.payin_gst !== undefined
                        ? parseFloat(item.payin_gst).toFixed(2)
                        : "NA"}
                    </td>
                    <td className="text-center align-middle">
                      {item.payin_gst !== undefined
                        ? parseFloat(item.settlementAmount).toFixed(2)
                        : "NA"}
                    </td>
                    <td className="text-center align-middle">
                      {item.settlement_time}
                    </td>
                    <td className="text-center align-middle">
                      {item.settlement_time == "5PM-8AM"
                        ? "03:00 PM"
                        : "11:00 PM"}
                    </td>
                    <td className="text-center align-middle">{item.indate}</td>
                    <td className="text-center align-middle">
                      {" "}
                      {item.status !== undefined && item.status == "SETTLED" ? (
                        <label
                          style={{
                            padding: "8px",
                            border: "1px solid",
                            height: "2rem",
                            backgroundColor: "rgba(43, 193, 85, 0.1)",
                            color: "rgba(43, 193, 85, 1)",
                            borderColor: "rgba(43, 193, 85, 1)",
                            borderRadius: ".4rem",
                            fontSize: ".8rem",
                          }}
                          className="badge badge-success"
                        >
                          {"Settled"}
                        </label>
                      ) : item.status !== undefined && item.status == "N" ? (
                        <label
                          style={{
                            padding: "8px",
                            borderRadius: ".4rem",
                            backgroundColor: "rgba(255, 0, 0, 0.1)",
                            color: "rgba(255, 0, 0, 1)",
                            border: "1px solid rgba(255, 0, 0, 1)",
                            borderColor: "rgba(255, 0, 0, 1)",
                            fontSize: "0.8rem",
                          }}
                          className="badge badge-danger"
                        >
                          {"Failed"}
                        </label>
                      ) : (
                        <label
                          style={{
                            padding: "8px",
                            borderRadius: ".4rem",
                            backgroundColor: "rgba(255, 238, 0, 0.1)",
                            color: "rgba(255, 196, 0, 1)",
                            border: "1px solid rgba(255, 238, 0, 1)",
                            borderColor: "rgba(255, 238, 0, 1)",
                            fontSize: "0.8rem",
                            textAlign: "center",
                          }}
                          className="badge badge-warning "
                        >
                          {"Unsettled"}
                        </label>
                      )}
                    </td>

                    <td className="text-center align-middle">
                      {user.merchantId == 2 && (
                        <button class="btn btn-danger" onClick={() => {
                          deleteLedgerEntryVerification(item.id)
                        }}>Delete</button>
                      )}
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
          {data.length <= 0 && <NoDataFound />}
          {/* <hr /> */}
          <Pagination
            page={currentPage}
            limit={pages}
            callback={(page, offsetPassed) => {
              if (currentPage != page) setCurrentPage(page);
              if (offset != offsetPassed) setOffset(offsetPassed);
            }}
            count={dataToDisplay.length}
            total={total}
          />
        </div>
      </div>

    </>
  );
};

export default MerchantPayinLedgerReport;

export const NoDataFound = () => {
  return (
    <div className="d-flex flex-column align-items-center justify-content-center p-3">
      <Lottie
        animationData={ComingSoonImage}
        alt="Coming Soon"
        style={{ height: "40vh", width: "100vw" }}
      />
      <strong>No Data Found</strong>
    </div>
  );
};
