import React, { useState, useEffect, useContext } from "react";

import './custom-tabs.css';
import Transactions, { Payout } from "./Transactions";
import { Ledger } from "./Ledger";
import Balances from "./Balances";
import Profile from "./Profile";
import { useAuth } from "../../context/AuthContext";

const MyAccount2 = () => {
    const [agentId, setAgentId] = useState("");

    let {
        user2: info,
      } = useAuth();

    const [activeTab, setActiveTab] = useState(0);

    const handleTabClick = (index) => {
        setActiveTab(index);
    };
    const [name, setName] = useState('');
    const [submittedData, setSubmittedData] = useState(null);


    return (


        <div className="p-4 bg-light" style={{ fontFamily: "auto" }}>

            <div style={{ background: "#fff" }}>
                {" "}
                <div
                    className="row justify-content-between mx-1 m-1 mb-3"
                    style={{ background: "" }}
                >
                    <h2 className="col"
                        style={{
                            margin: "0",
                            fontWeight: "bolder",
                        }}
                    >
                        My Account
                    </h2>
                    <div className="col d-flex justify-content-end">
                    <button
                        className="  btn "
                    //    onClick={logoutDeveloperLogin}
                        style={{ width: "7rem" , background:"#2c3e50"  , color:"white"}}
                    >
                        Log Out
                    </button>
                    </div>
                </div>
            </div>
            <div>
                <div>
                    <div className="card m-0 p-0 pt-3">
                        <div className="card-body m-0 p-0">
                            <div className="">
                                <div defaultActiveKey="profile" className=" tab-header justify-content-between">
                                    <div
                                        className={`tab-item ${activeTab === 0 ? "active" : ""}`}
                                        onClick={() => handleTabClick(0)}
                                    >
                                        Profile
                                    </div>
                                    <div
                                        className={`tab-item ${activeTab === 1 ? "active" : ""}`}
                                        onClick={() => handleTabClick(1)}
                                    >
                                        Balances
                                    </div>
                                    <div
                                        className={`tab-item ${activeTab === 2 ? "active" : ""}`}
                                        onClick={() => handleTabClick(2)}
                                    >
                                        Ledger
                                    </div>

                                    <div
                                        className={`tab-item ${activeTab === 3 ? "active" : ""}`}
                                        onClick={() => handleTabClick(3)}
                                    >
                                        Transactions
                                    </div>

                                    <div
                                        className={`tab-item ${activeTab === 4 ? "active" : ""}`}
                                        onClick={() => handleTabClick(4)}
                                    >
                                        Payout
                                    </div>

                                </div>
                                <div className="tab-content">
                                    {activeTab === 0 && <div><Profile agentId={agentId} /></div>}
                                    {activeTab === 1 && <div><Balances agentId={agentId} /></div>}
                                    {activeTab === 2 && <div><Ledger agentId={agentId} /></div>}
                                    {activeTab === 3 && <div> <Transactions agentId={agentId} /> </div>}
                                    {activeTab === 4 && <div> <Payout agentId={agentId} /></div>}
                                </div>
                            </div>
                        </div>
                    </div>



                </div>
            </div>

        </div>

    );
};

export default MyAccount2;
