import React, { useState } from "react";
import Details from "./Details";
import DetailsByNo from "./DetailsByNo";

const TransactionDetails = () => {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div
      style={{
        fontFamily: 'Arial, sans-serif',
        display: 'flex',
        justifyContent: 'center',
        padding: 0,
        margin: 0,
        width: '100%',
        height: 'auto', 
      }}
    >
      <div
        style={{
          backgroundColor: '#fff',
          borderRadius: '10px',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
          width: '100%',
          maxWidth: '1410px', 
          padding: '20px',
          textAlign: 'center',
          marginTop: '4rem',
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-around',
            marginBottom: '20px',
          }}
        >
          <div
            onClick={() => handleTabClick(0)}
            style={{
              flex: 1,
              padding: '10px',
              cursor: 'pointer',
              backgroundColor: activeTab === 0 ? '#2c3e50' : '#ecf0f1',
              color: activeTab === 0 ? '#fff' : '#34495e',
              border: 'none',
              fontSize: '16px',
              outline: 'none',
              transition: 'background-color 0.3s ease',
              borderRadius: '4px',
            }}
          >
            Transaction Details
          </div>
          <div
            onClick={() => handleTabClick(1)}
            style={{
              flex: 1,
              padding: '10px',
              cursor: 'pointer',
              backgroundColor: activeTab === 1 ? '#2c3e50' : '#ecf0f1',
              color: activeTab === 1 ? '#fff' : '#34495e',
              border: 'none',
              fontSize: '16px',
              outline: 'none',
              transition: 'background-color 0.3s ease',
              borderRadius: '4px',
            }}
          >
            Details by Number          
              </div>
        </div>
        <div
          style={{
            fontSize: '18px',
            padding: '20px',
            borderRadius: '10px',
          }}
        >
          {activeTab === 0 && <Details status={'Details'} />}
          {activeTab === 1 && <DetailsByNo status={'Details By NO'} />}
        </div>
      </div>
    </div>


  );
};

export default TransactionDetails;
