import React, { useEffect, useState, Component } from "react";

function ApiReference() {
    const [formData, setFormData] = useState({
        exchange_application_id: 'gateway.equal.dev',
        reference_id: 'user_application_ic',
        business_user_id: ''
    });

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = () => {
        console.log('Request Sent:', formData);
        alert('API request triggered with data: ' + JSON.stringify(formData));
    };

    const containerStyle = {
        maxWidth: '1200px',
        margin: '0 auto',
        backgroundColor: '#fff',
        padding: '20px',
        borderRadius: '10px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        fontFamily: 'Arial, sans-serif',
    };

    const h1Style = {
        fontSize: '24px',
        marginBottom: '10px',
    };

    const h2Style = {
        fontSize: '18px',
        color: '#888',
    };

    const requestHistoryStyle = {
        margin: '20px 0',
        backgroundColor: '#f5f5f5',
        padding: '15px',
        borderRadius: '10px',
    };

    const paramItemStyle = {
        marginBottom: '15px',
    };

    const labelStyle = {
        display: 'block',
        marginBottom: '5px',
    };

    const inputStyle = {
        width: '100%',
        padding: '10px',
        border: '1px solid #ccc',
        borderRadius: '5px',
    };

    const codeSectionStyle = {
        marginTop: '20px',
        backgroundColor: '#2c3e50',
        color: 'white',
        padding: '15px',
        borderRadius: '10px',
        fontFamily: "'Courier New', Courier, monospace",
    };

    const preStyle = {
        margin: 0,
    };

    const responseSectionStyle = {
        marginTop: '20px',
        textAlign: 'center',
    };

    const buttonStyle = {
        backgroundColor: '#28a745',
        color: 'white',
        padding: '10px 20px',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',

    };

    const buttonHoverStyle = {
        backgroundColor: '#218838',
    };


    return (
        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
        {/* Left Column - col-8 equivalent */}
        <div style={{ flexBasis: '66.66%', paddingRight: '20px' }}>
          <div style={containerStyle} className="mt-3">
            <h1 style={h1Style}><b>Initialise Exchange Transaction</b></h1>
            <h3 style={h2Style}>API to Initialise Exchange Transaction</h3>
      
            <div style={requestHistoryStyle}>
              <h4>Request History</h4>
              <div>Make a request to see history.</div>
              <div>0 Requests This Month</div>
            </div>
            <div className="m-2">
              After Initialising Exchange you can execute various predefined journeys, the journeys would need transaction_id generated from hitting Initialise Exchange Transaction API.
            </div>
            <div className="params-section m-2"
              style={{
                margin: '20px 0',
                backgroundColor: '#f5f5f5',
                padding: '15px',
                borderRadius: '10px',
              }}>
              <h5><b>Body Params</b></h5>
              <div style={paramItemStyle}>
                <label style={labelStyle}><b>exchange_application_id</b></label>
                <div>string {""}
                  <span style={{ color: "red" }}>required</span> {''}
                  Defaults to gateway.equal.dev_test Configuration identifier given by Equal during onboarding for your exchange application, based on your requirement, we would create a unique workflow with different configurations.
                </div>
                <input
                  type="text"
                  name="exchange_application_id"
                  value={formData.exchange_application_id}
                  onChange={handleChange}
                  style={inputStyle}
                />
              </div>
              <div style={paramItemStyle}>
                <label style={labelStyle}><b>reference_id</b>{""} string</label>
                <div>Also referred to as idempotency_id. This is a unique identifier to ensure the transaction is processed only once, it helps in avoiding duplicate transactions, if you don't want multiple exchange transactions for the same workflow for same users, we suggest you to pass your transaction ID</div>
                <input
                  type="text"
                  name="reference_id"
                  value={formData.reference_id}
                  onChange={handleChange}
                  style={inputStyle}
                />
              </div>
              <div style={paramItemStyle}>
                <label style={labelStyle}><b>business_user_id</b>{""} string</label>
                <div>The identifier for the user in the business’s system, it is used to map the verification results back to the business’s user database.</div>
                <input
                  type="text"
                  name="business_user_id"
                  value={formData.business_user_id}
                  onChange={handleChange}
                  style={inputStyle}
                />
              </div>
            </div>
          </div>
        </div>
      
        {/* Right Column - col-4 equivalent */}
        <div style={{ flexBasis: '33.33%', paddingLeft: '20px' }}>
        <div style={{
            padding: '10px',
            backgroundColor: '#f5f5f5',
            borderRadius: '10px',
            marginBottom: '20px'
        }}>
            <h4 style={{ margin: '0' }}>LANGUAGE</h4>
            <div style={{ display: 'flex', gap: '10px', marginTop: '10px' }}>
                <div style={{ padding: '10px', backgroundColor: '#e1e1e1', borderRadius: '5px' }}>Shell</div>
                <div style={{ padding: '10px', backgroundColor: '#e1e1e1', borderRadius: '5px' }}>Node</div>
                <div style={{ padding: '10px', backgroundColor: '#e1e1e1', borderRadius: '5px' }}>Ruby</div>
                <div style={{ padding: '10px', backgroundColor: '#e1e1e1', borderRadius: '5px' }}>PHP</div>
                <div style={{ padding: '10px', backgroundColor: '#e1e1e1', borderRadius: '5px' }}>Python</div>
            </div>
        </div> 
          <div style={codeSectionStyle}>
            <h3>Code Example</h3>
            <pre style={preStyle}>
              {`
      $client = new \\GuzzleHttp\\Client();
      $response = $client->request('POST', 'https://business-api.test.equal.in/ie/pidg/transaction/init', {
          'body' => '{"exchange_application_id":"${formData.exchange_application_id}"}',
          'headers' => [
              'accept' => 'application/json',
              'content-type' => 'application/json',
          ],
      });
      echo $response->getBody();
              `}
            </pre>
            <div style={responseSectionStyle}>
              <div style={{ textAlign: 'right' }}>
                <button
                  onClick={handleSubmit}
                  style={buttonStyle}
                  onMouseOver={(e) => e.target.style.backgroundColor = buttonHoverStyle.backgroundColor}
                  onMouseOut={(e) => e.target.style.backgroundColor = buttonStyle.backgroundColor}
                >
                  Try It!
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      
    );
}

export default ApiReference;
