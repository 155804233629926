import React, { useEffect, useState } from "react";
import { Badge, Button, Modal } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import { useHistory } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import Image1 from "../../assets/images/Edit Details.png"
import Image2 from "../../assets/images/Add Load.png"

const arr = [{ label: "Name", value: "Name" }];

const ViewAllMerchant = () => {
  const { user } = useAuth();
  const history = useHistory();
  const [data, setData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedMerchant, setSelectedMerchant] = useState(null);
  const [loading, setLoading] = useState(true);
  const ViewAllMerchant = async () => {
    console.log(ViewAllMerchant, "shee");
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        merchantId: user.merchantId,
        merchantSecret: "3DjyvAQvTZlk86g5jvHI7dfS",
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `https://bumppy.in/payin/api/getAllChildMerchant.php`,
        requestOptions
      );
      const data = await res.json();
      console.log("Merchant", data);
      if (data.status === "00") {
        setData(data.data);
      }
      setLoading(false);
    } catch (error) {
      console.log("error", error);
      setLoading(false);
    }
  };
  const handleDetailsClick = (merchant) => {
    setSelectedMerchant(merchant);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setSelectedMerchant(null);
    setShowModal(false);
  };
  useEffect(() => {
    ViewAllMerchant();
  }, []);

  return (
    <>
     <div className="card m-4">
  <div className="p-2">
    <h4>View All Merchant</h4>
    <div>
      <table className="table" style={{ fontFamily: "Poppins" }}>
        <thead>
          <tr>
            <th className="text-white text-center py-3 " style={{ width: "10%" , background: "#2c3e50" }}>
              Merchant Id
            </th>
            <th className=" text-white text-center py-3" style={{ width: "10%" , background: "#2c3e50" }}>
              UserName
            </th>
            <th className=" text-white text-center py-3" style={{ width: "15%" ,background: "#2c3e50" }}>
              Business
            </th>
            <th className=" text-white text-center py-3" style={{ width: "10%" ,background: "#2c3e50" }}>
              Bank
            </th>
            <th className=" text-white text-center py-3" style={{ width: "10%" ,background: "#2c3e50" }}>
              Account
            </th>
            <th className=" text-white text-center py-3" style={{ width: "10%" ,background: "#2c3e50" }}>
              IFSC
            </th>
            <th className=" text-white text-center py-3" style={{ width: "10%" , background: "#2c3e50" }}>
              Balance
            </th>
            <th className=" text-white text-center py-3" style={{ width: "10%" , background: "#2c3e50" }}>
              Virtual Balance
            </th>
            <th className=" text-center py-3 text-white" style={{ width: "15%" , background: "#2c3e50" }}>
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          {loading ? (
            <tr>
              <td colSpan="9" className="text-center">
                Loading...
              </td>
            </tr>
          ) : (
            data.map((item) => (
              <tr key={item.UserName}>
                <td className="text-center align-middle">{item.merchantId || "NA"}</td>
                <td className="text-center align-middle">{item.UserName || "NA"}</td>
                <td className="text-center align-middle">{item.BusinessName || "NA"}</td>
                <td className="text-center align-middle">{item.BankName || "NA"}</td>
                <td className="text-center align-middle">{item.AccountNumber || "NA"}</td>
                <td className="text-center align-middle">{item.IfscCode || "NA"}</td>
                <td className="text-center align-middle">{item.PaymentAPIBalance || "NA"}</td>
                <td className="text-center align-middle">{item.virtualBalance || "NA"}</td>
                <td className="text-center align-middle">

                <div
                        style={{
                          position: "relative",
                          display: "inline-block",
                        }}
                        onMouseOver={(e) => {
                          e.currentTarget.querySelector(".tooltip-text").style.visibility = "visible";
                          e.currentTarget.querySelector(".tooltip-text").style.opacity = "1";
                        }}
                        onMouseOut={(e) => {
                          e.currentTarget.querySelector(".tooltip-text").style.visibility = "hidden";
                          e.currentTarget.querySelector(".tooltip-text").style.opacity = "0";
                        }}
                      >
                        <Button
                          style={{
                            borderRadius: "5px",
                            border: "1px none",
                            backgroundColor: "rgba(255, 255, 255, 1)",
                            color: "rgba(255, 164, 27, 1)",
                            borderColor: "rgba(255, 255, 255, 1)",
                          }}
                          className="btn btn-sm rounded-100 m-0 p-1"
                          onClick={() => handleDetailsClick(item)}
                        >
                          <div className="hover-container">
                            <a>
                              <img
                                src={Image2}
                                alt="Clickable Image"
                              />
                            </a>
                          </div>
                        </Button>
                        <span
                          style={{
                            visibility: "hidden",
                            width: "100px",
                            backgroundColor: "#555",
                            color: "#fff",
                            textAlign: "center",
                            borderRadius: "6px",
                            padding: "5px 0",
                            position: "absolute",
                            zIndex: "1",
                            bottom: "125%",
                            left: "50%",
                            marginLeft: "-50px",
                            opacity: "0",
                            transition: "opacity 0.3s",
                          }}
                          className="tooltip-text"
                        >
                          Details
                        </span>
                      </div>
                      <div
                        style={{
                          position: "relative",
                          display: "inline-block",
                        }}
                        onMouseOver={(e) => {
                          e.currentTarget.querySelector(".tooltip-text").style.visibility = "visible";
                          e.currentTarget.querySelector(".tooltip-text").style.opacity = "1";
                        }}
                        onMouseOut={(e) => {
                          e.currentTarget.querySelector(".tooltip-text").style.visibility = "hidden";
                          e.currentTarget.querySelector(".tooltip-text").style.opacity = "0";
                        }}
                      >
                        <Button
                          style={{
                            borderRadius: "5px",
                            border: "1px none",
                            backgroundColor: "rgba(255, 255, 255, 1)",
                            color: "rgba(255, 164, 27, 1)",
                            borderColor: "rgba(255, 255, 255, 1)",
                          }}
                          className="btn btn-sm rounded-100 m-0 p-1"
                          onClick={() =>
                            history.push(`/merchant/set-commercials?id=${item.merchantId}`)
                          }
                        >
                          <div className="hover-container">
                            <a>
                              <img
                                src={Image1}
                                alt="Clickable Image"
                              />
                            </a>
                          </div>
                        </Button>

                        <span
                          style={{
                            visibility: "hidden",
                            width: "100px",
                            backgroundColor: "#555",
                            color: "#fff",
                            textAlign: "center",
                            borderRadius: "6px",
                            padding: "5px 0",
                            position: "absolute",
                            zIndex: "1",
                            bottom: "125%",
                            left: "50%",
                            marginLeft: "-50px",
                            opacity: "0",
                            transition: "opacity 0.3s",
                          }}
                          className="tooltip-text"
                        >
                          Set Commercials
                        </span>
                      </div>
                  
                  {/* <div style={{ display: "flex", gap: "10px", justifyContent: "center" }}>
                    <button
                      type="button"
                      className="btn btn-primary"
                      style={{ width: "120px" }}
                      onClick={() => handleDetailsClick(item)}
                    >
                      Details
                    </button>
                    <button
                      type="button"
                      className="btn btn-primary"
                      style={{ width: "120px" }}
                      onClick={() =>
                        history.push(`/merchant/set-commercials?id=${item.merchantId}`)
                      }
                    >
                      Set Commercials
                    </button>
                  </div> */}
                </td>
              </tr>
            ))
          )}
        </tbody>
      </table>
    </div>
  </div>
</div>

      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Modal.Body>
            {selectedMerchant && (
              <div>
                <div className="row">
                  <div className="col">
                    {" "}
                    <label htmlFor="merchantId" className="form-label">
                      Merchant:
                    </label>
                  </div>
                  <div className="col">
                    {" "}
                    <div>{selectedMerchant.merchantId || "NA"}</div>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    {" "}
                    <label htmlFor="merchantId" className="form-label">
                      Merchant Secret:
                    </label>
                  </div>
                  <div className="col">
                    <div>{selectedMerchant.merchantSecret || "NA"}</div>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    {" "}
                    <label htmlFor="merchantId" className="form-label">
                      Password Plain:
                    </label>
                  </div>
                  <div className="col">
                    <div>{selectedMerchant.password_plain || "NA"}</div>
                  </div>
                </div>
              </div>
            )}
          </Modal.Body>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ViewAllMerchant;
