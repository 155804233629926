import React, { useState, useEffect, useContext } from "react";

import "../api_partner/custom-tabs.css";
import MerchantPayinLedgerReport from "../MerchantPayinLedgerReport";
import SuspectedReport from "./SuspectedReport";
import { useAuth } from "../../context/AuthContext";

const DashboardReports = () => {

    // auth user
    const { user } = useAuth();

  const [activeTab, setActiveTab] = useState(4);

  const handleTabClick = (index) => {
    setActiveTab(index);
  };
  const [name, setName] = useState("");

  return (
    <div className="p-4 bg-light" style={{ fontFamily: "auto" }}>
      <div>
        <div>
          <div className="card m-0 p-0 pt-3">
            <div className="card-body m-0 p-0">
              <div className="">
                <div
                  defaultActiveKey="profile"
                  className=" tab-header justify-content-between"
                >
                  <div
                    className={`tab-item ${activeTab === 0 ? "active" : ""}`}
                    onClick={() => handleTabClick(0)}
                  >
                    Suspected
                  </div>
                  <div
                    className={`tab-item ${activeTab === 5 ? "active" : ""}`}
                    onClick={() => handleTabClick(5)}
                  >
                    Dispute
                  </div>
                  <div
                    className={`tab-item ${activeTab === 4 ? "active" : ""}`}
                    onClick={() => handleTabClick(4)}
                  >
                    Merchant Ledger
                  </div>
                  <div
                    className={`tab-item ${activeTab === 3 ? "active" : ""}`}
                    onClick={() => handleTabClick(3)}
                  >
                    Refund
                  </div>
            
                </div>
                <div className="tab-content">
                  {activeTab === 0 && (
                    <div>
                      <SuspectedReport status={"SUSPECTED"} />
                    </div>
                  )}

                  {activeTab === 5 && (
                    <div>
                      <SuspectedReport status={"DISPUTE"} />
                    </div>
                  )}
                  {activeTab === 4 && (
                    <div>
                      <MerchantPayinLedgerReport status={"N"} />
                    </div>
                  )}

{activeTab === 3 && (
                    <div>
                      <SuspectedReport status={"DISPUTE"} />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardReports;
