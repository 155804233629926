import React, { useState, useEffect, useContext } from "react";

import './api_partner/custom-tabs.css';
import { useAuth } from "../context/AuthContext";
import AllUPIIntentReport from "./AllUPIIntentReport";
import GetUpiIntentReport2 from "./UpiIntentReport3";

const PayinReport2 = () => {
    const [agentId, setAgentId] = useState("");

    let {
        user2: info,
      } = useAuth();

    const [activeTab, setActiveTab] = useState(0);

    const handleTabClick = (index) => {
        setActiveTab(index);
    };
    const [name, setName] = useState('');


    return (


        <div className="p-4 bg-light" style={{ fontFamily: "auto" }}>

          
            <div>
                <div>
                    <div className="card m-0 p-0 pt-3">
                        <div className="card-body m-0 p-0">
                            <div className="">
                                <div defaultActiveKey="profile" className=" tab-header justify-content-between">
                                    <div
                                        className={`tab-item ${activeTab === 0 ? "active" : ""}`}
                                        onClick={() => handleTabClick(0)}
                                    >
                                        SUCCESS
                                    </div>
                                    <div
                                        className={`tab-item ${activeTab === 5 ? "active" : ""}`}
                                        onClick={() => handleTabClick(5)}
                                    >
                                        Sespected
                                    </div>
                                    <div
                                        className={`tab-item ${activeTab === 4 ? "active" : ""}`}
                                        onClick={() => handleTabClick(4)}
                                    >
                                        Dispute
                                    </div>
                                    <div
                                        className={`tab-item ${activeTab === 3 ? "active" : ""}`}
                                        onClick={() => handleTabClick(3)}
                                    >
                                        Refund
                                    </div>
                                    <div
                                        className={`tab-item ${activeTab === 1 ? "active" : ""}`}
                                        onClick={() => handleTabClick(1)}
                                    >
                                        Expired
                                    </div>
                                    <div
                                        className={`tab-item ${activeTab === 2 ? "active" : ""}`}
                                        onClick={() => handleTabClick(2)}
                                    >
                                        Pending
                                    </div>
                                
                                  

                                </div>
                                <div className="tab-content">
                                    {activeTab === 0 && <div><GetUpiIntentReport2 status={'SUCCESS'} /></div>}
                                
                                    {activeTab === 1 && <div><GetUpiIntentReport2 status={'EXPIRED'} /></div>}
                                    {activeTab === 2 && <div><GetUpiIntentReport2 status={'N'} /></div>}
                                    {activeTab === 3 && <div><GetUpiIntentReport2 status={'REFUND'} /></div>}
                                    {activeTab === 4 && <div><GetUpiIntentReport2 status={'DISPUTE'} /></div>}
                                    {activeTab === 5 && <div><GetUpiIntentReport2 status={'SUSPECTED'} /></div>}
                                </div>
                            </div>
                        </div>
                    </div>



                </div>
            </div>

        </div>

    );
};

export default PayinReport2;
